<template>
	<div class="signup">
		<h1><font-awesome-icon icon="user-plus" /> Registrieren</h1>
		<div v-if="error" class="alert alert-danger">{{error}}</div>
		<form action="#" @submit.prevent="submit">
			<div class="input">
				<input type="text" name="name" v-model="user.name" required autofocus placeholder="Name" />
			</div>
			<div class="input">
				<input type="email" name="email" v-model="user.email" required placeholder="eMail" />
			</div>
			<div class="input">
				<input type="password" name="password" v-model="user.password" required placeholder="Passwort" />
			</div>
			<div class="input">
				<button type="submit"><font-awesome-icon icon="user-plus" /> registrieren</button>
			</div>
		</form>
	</div>
</template>

<script>
//import { log } from 'util'
import { db, auth } from "../db"

export default {
	data() {
		return {
			user: {
				name: '',
				email: '',
				passsword: ''
			},
			error: null
		}
	},
	methods: {
		submit(e){
			auth
				.createUserWithEmailAndPassword(this.user.email, this.user.password)
				.then(data => {
					data.user
						.updateProfile({
							displayName: this.user.name
						})
						.then(() => {
							db.collection('users').doc(data.user.uid).set({
								displayName: this.user.name,
								email: this.user.email
							}).then(() => {
								this.$router.replace('home');
							}).catch(err => {
								this.errorMessage = err.message;
							});
						})
				})
				.catch(err => {
					this.error = err.message;
				})
		}
	}
}
</script>

<style lang="scss" scoped>
.signup {
	background: #fff;
	padding: 32px;
	box-shadow: 0px -2px 4px rgba(0,0,0,0.1);
	
	width: 100vw;
	position: absolute;
	bottom: 0;

	box-sizing: border-box;

	h1 {
		margin: 0;
		padding: 0;
		font-weight: 400;
		font-size: 28px;
		margin-bottom: .5em;
	}

	.input {
		padding: 10px 0;
		display: flex;
		input {
			flex: 1;
			background: #f2f1f1;
			border: 0;			
			box-sizing: border-box;
			font-size: 18px;
			line-height: 44px;
			padding: 10px;
		}
	}

	button {
		background: #ce0d59;
		border: 0;
		font-size: 18px;
		line-height: 44px;
		padding: 8px;
		width: 100%;
		color: #fff;
	}
}
</style>